/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react'
import type { DropdownProps } from '../Dropdown'
import Dropdown from '../Dropdown'
import type { ItemType } from './Breadcrumb'
import BreadcrumbSeparator from './BreadcrumbSeparator'
import { renderItem } from './useItemRender'
import { FaAngleDown } from 'react-icons/fa'

export interface SeparatorType {
  separator?: React.ReactNode
  key?: React.Key
}

type MenuType = NonNullable<DropdownProps['menu']>
interface MenuItem {
  key?: React.Key
  title?: React.ReactNode
  label?: React.ReactNode
  path?: string
  href?: string
}

export interface BreadcrumbItemProps extends SeparatorType {
  href?: string
  menu?: Omit<MenuType, 'items'> & {
    items?: MenuItem[]
  }
  dropdownProps?: DropdownProps
  onClick?: React.MouseEventHandler<HTMLAnchorElement | HTMLSpanElement>
  className?: string
  children?: React.ReactNode
  // Deprecated
  /** @deprecated Please use `menu` instead */
  // overlay?: DropdownProps['overlay'];
}

export const InternalBreadcrumbItem: React.FC<BreadcrumbItemProps> = (props) => {
  const {
    separator = '/',
    children,
    menu,
    // overlay,
    dropdownProps,
  } = props

  /** If overlay is have Wrap a Dropdown */
  const renderBreadcrumbNode = (breadcrumbItem: React.ReactNode) => {
    if (menu != null) {
      const mergeDropDownProps: DropdownProps = {
        ...dropdownProps,
      }

      return (
        <Dropdown {...mergeDropDownProps}>
          <span className='dropdown-overlay-link'>
            {breadcrumbItem}
            <FaAngleDown />
          </span>
        </Dropdown>
      )
    }
    return breadcrumbItem
  }

  // wrap to dropDown
  const link = renderBreadcrumbNode(children)
  if (link !== undefined && link !== null) {
    return (
      <>
        <li>{link}</li>
        {separator && <BreadcrumbSeparator>{separator}</BreadcrumbSeparator>}
      </>
    )
  }
  return null
}

type CompoundedComponent = React.FC<BreadcrumbItemProps> & {
  /** @internal */
  BREADCRUMB_ITEM: boolean
}

const BreadcrumbItem: CompoundedComponent = (props) => {
  const { children, href, ...restProps } = props
  const prefixCls = 'breadcrumb'
  return (
    <InternalBreadcrumbItem {...restProps}>
      {renderItem(prefixCls, restProps as ItemType, children, href)}
    </InternalBreadcrumbItem>
  )
}

BreadcrumbItem.BREADCRUMB_ITEM = true

export default BreadcrumbItem
