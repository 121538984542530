/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react'
import type { BreadcrumbItemType, BreadcrumbSeparatorType, ItemType } from './Breadcrumb'

type MergedType = BreadcrumbItemType & {
  //NOSONAR
  children?: ItemType['children'] //NOSONAR
}

export default function useItems(
  items?: ItemType[],
  routes?: ItemType[],
): Array<Partial<MergedType & BreadcrumbSeparatorType>> | null {
  return useMemo<ItemType[] | null>(() => {
    if (items != null) {
      return items
    }

    return null
  }, [items, routes])
}
