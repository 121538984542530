/* eslint-disable @typescript-eslint/naming-convention */
import classNames from 'classnames'
import * as React from 'react'

type widthUnit = number | string

export interface SkeletonParagraphProps {
  prefixCls?: string
  className?: string
  style?: React.CSSProperties
  width?: widthUnit | widthUnit[]
  rows?: number
}

const Paragraph: React.FC<SkeletonParagraphProps> = (props) => {
  const getWidth = (index: number) => {
    const { width, rows = 2 } = props
    if (Array.isArray(width)) {
      // eslint-disable-next-line security/detect-object-injection
      return width[index]
    }
    // last paragraph
    if (rows - 1 === index) {
      return width
    }
    return undefined
  }
  const { prefixCls, className, style, rows } = props
  const rowList = [...Array(rows)].map((_, index) => (
    <li key={rows} style={{ width: getWidth(index) }} />
  ))
  return (
    <ul className={classNames(prefixCls, className)} style={{ ...style }}>
      {rowList}
    </ul>
  )
}

export default Paragraph
