/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'

export type AnyObject = Record<PropertyKey, any>
export interface Option {
  keepEmpty?: boolean
}

export interface CSPConfig {
  nonce?: string
}

export type DirectionType = 'ltr' | 'rtl' | undefined

export interface ComponentStyleConfig {
  className?: string
  style?: React.CSSProperties
}

export type PopupOverflow = 'viewport' | 'scroll'

export interface ConfigConsumerProps {
  direction?: DirectionType
  skeleton?: ComponentStyleConfig
  breadcrumb?: ComponentStyleConfig
}

export const ConfigContext = React.createContext<ConfigConsumerProps>({})

export const { Consumer: ConfigConsumer } = ConfigContext
