import React from 'react'

interface ExternalLinkProps {
  className?: string
  href?: string
  title?: string
  children?: React.ReactNode
}

const ExternalLink = (props: ExternalLinkProps): React.JSX.Element => {
  const { className = '', href = '', title = '', children } = props
  return (
    <a
      href={href}
      title={title}
      className={className}
      rel='nofollow noreferrer noopener'
      target='_blank'
    >
      {children}
    </a>
  )
}

export default ExternalLink
