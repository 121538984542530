import styled from 'styled-components'

export const StyleBreadcrumb = styled.div`
  .breadcrumb {
    max-width: 100%;
    overflow-x: auto;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    > ul,
    > ol {
      display: flex;
      align-items: center;
      white-space: nowrap;
      min-height: -moz-min-content;
      min-height: min-content;
    }
    > ul,
    > ol {
      > li {
        display: flex;
        align-items: center;
        margin-right: 0.35rem;
        color: rgba(0, 0, 0, 0.45);
        &:last-child {
          margin-left: 0rem;
          margin-right: 0rem;
          color: rgba(0, 0, 0, 0.88);
          > .breadcrumb-link {
            color: rgba(0, 0, 0, 0.88);
          }
        }
      }
    }
    .breadcrumb-link {
      color: rgba(0, 0, 0, 0.45);
      transition: color 0.2s;
      padding: 0 4px;
      border-radius: 4px;
      height: 22px;
      display: flex;
      align-items: center;
      margin-inline: -4px;
      > * + * {
        --tw-space-x-reverse: 0;
        margin-right: calc(0.35rem * var(--tw-space-x-reverse));
        margin-left: calc(0.35rem * calc(1 - var(--tw-space-x-reverse)));
      }
    }
    .breadcrumb-separator {
      color: #b5b5c3;
    }
  }
`
