import React from 'react'
import Svg from 'react-inlinesvg'
import { StyleIconSvg } from './style'

interface IconSvgProps {
  className?: string
  path: string
  svgClassName?: string
  title?: string
}

const IconSvg = (props: IconSvgProps): React.JSX.Element => {
  const { className = '', path = '', svgClassName = '', title = '' } = props
  return (
    <StyleIconSvg className={`svg-icon ${className}`}>
      <Svg className={svgClassName} src={path} title={title} height='100%' />
    </StyleIconSvg>
  )
}

export default IconSvg
