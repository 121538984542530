import styled from 'styled-components'

export const StyleTextArea = styled.div`
  position: relative;
  width: 100%;
  align-items: center;
  display: flex;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  flex-wrap: wrap;
  &.disabled {
    color: rgba(0, 0, 0, 0.25);
  }
  textarea {
    width: 100%;
    flex-shrink: 1;
    height: 3rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    font-size: 1rem;
    line-height: 1.5rem;
    border-bottom-width: 1px;
    border-color: #000000;
    background: transparent;
    &:focus {
      outline-offset: 0px;
    }
    &.input-lg {
      height: 3.5rem;
      min-height: 3.5rem;
    }
    &.input-warning,
    &.input-error,
    &.input-success {
      padding-right: 3.25rem;
      border-width: 2px;
      border-radius: calc(0.625rem - 2px);
    }
    &.input-warning {
      border-color: hsl(var(--wa));
    }
    &.input-error {
      border-color: hsl(var(--er));
    }
    &.input-success {
      border-color: hsl(var(--su));
    }
    &.input-info {
      border-color: hsl(var(--in));
    }
  }
`
