import classNames from 'classnames'
import * as React from 'react'
import type { SkeletonElementProps } from './Element'
import { FaRegChartBar } from 'react-icons/fa'
import { StyleskeletonClsLoading } from './style'

export interface SkeletonNodeProps extends Omit<SkeletonElementProps, 'size' | 'shape'> {
  fullSize?: boolean //NOSONAR
  children?: React.ReactNode
  rootClassName?: string
  active?: boolean
}

const SkeletonNode: React.FC<SkeletonNodeProps> = (props) => {
  const { className, rootClassName, style, active, children } = props
  const prefixCls = 'skeleton'

  const cls = classNames(
    prefixCls,
    `${prefixCls}-element`,
    {
      [`${prefixCls}-active`]: active,
    },

    className,
    rootClassName,
  )

  const content = children ?? <FaRegChartBar />

  return (
    <StyleskeletonClsLoading className={cls}>
      <div className={classNames(`${prefixCls}-image`, className)} style={{ ...style }}>
        {content}
      </div>
    </StyleskeletonClsLoading>
  )
}

export default SkeletonNode
