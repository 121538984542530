import styled from 'styled-components'

export const StyleFooter = styled.div`
  transition:
    left 0.3s ease,
    right 0.3s ease;
  .footer_icon {
    background-color: #fff;
    padding: 14px;
    border-radius: 12px;
    height: fit-content;
    width: fit-content;
    color: #1990ff;
  }
`
