import * as React from 'react'
import { StyleDivider } from './style'
import { CSSProperties } from 'react'
import classNames from 'classnames'

export interface DividerProps {
  prefixCls?: string
  type?: 'horizontal' | 'vertical'
  orientation?: 'left' | 'right' | 'center'
  orientationMargin?: string | number
  className?: string
  rootClassName?: string
  children?: React.ReactNode
  dashed?: boolean
  style?: React.CSSProperties
  plain?: boolean
}

const Divider: React.FC<DividerProps> = (props) => {
  const {
    type = 'horizontal',
    orientation = 'center',
    orientationMargin,
    className = '',
    rootClassName = '',
    children,
    dashed,
    plain,
    ...restProps
  } = props
  const typeDivider = type === 'vertical' ? 'vertical' : 'horizontal'
  const hasCustomMarginLeft = orientation === 'left' && orientationMargin != null
  const hasCustomMarginRight = orientation === 'right' && orientationMargin != null
  let noMargin
  if (hasCustomMarginLeft) {
    noMargin = 'divider-no-default-orientation-margin-left'
  } else if (hasCustomMarginRight) {
    noMargin = 'divider-no-default-orientation-margin-right'
  } else {
    noMargin = ''
  }
  let orientationDivider
  if (orientation === 'left') {
    orientationDivider = `divider-with-text divider-text-left ${noMargin}`
  } else if (orientation === 'right') {
    orientationDivider = `divider-with-text divider-text-right ${noMargin}`
  } else {
    orientationDivider = `divider-with-text divider-text-center ${noMargin}`
  }
  const dashedDivider = dashed ? 'divider-dashed' : ''
  const plainDivider = plain ? 'divider-plain' : ''

  const classString = classNames(
    typeDivider,
    dashedDivider,
    plainDivider,
    orientationDivider,
    className,
    rootClassName,
  )

  const memoizedOrientationMargin =
    typeof orientationMargin === 'number' ? orientationMargin : Number(orientationMargin)

  const innerStyle: CSSProperties = {
    ...(hasCustomMarginLeft && { marginLeft: memoizedOrientationMargin }),
    ...(hasCustomMarginRight && { marginRight: memoizedOrientationMargin }),
  }

  return (
    <StyleDivider className={classString} {...restProps}>
      {children && type !== 'vertical' && (
        <span className='inner-text' style={innerStyle}>
          {children}
        </span>
      )}
    </StyleDivider>
  )
}

export default Divider
